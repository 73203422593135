<template>
  <div class="balance">
    <div class="sub-wrap balance-index">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../../assets/icons/guthaben.svg" />
            <h2>{{ $t("global.balance") }}</h2>
            <h3>{{ $t("balance.info") }}</h3>
          </div>
        </div>

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("global.balance") }}</h3>
            <p class="header-action">
              <router-link to="/balance/payouts/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("balance.getPayed") }}</span>
              </router-link>
            </p>
          </header>
          <div class="content-body">
            <div v-if="payment_loading">
              <div class="loading-wrap" style="margin: 0; padding: 10px 0">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>
              <p class="balance" style="font-size: 40px; padding: 20px 0; text-align: center; width: 100%">
                {{ user.balance_formatted }}
              </p>
            </div>
          </div>
        </div>

        <!--<div class="row row-gutter-20">
          <div class="col-12">
            <div class="content-wrap">
              <header class="content-header">
                <h3>{{ $t("global.balance") }}</h3>
              </header>
              <div class="content-body">
                <div v-if="payment_loading">
                  <div class="loading-wrap" style="margin:0;padding:10px 0">
                    <div class="loading-spinner"></div>
                  </div>
                </div>
                <div v-else>
                  <p class="balance" style="font-size:40px;padding:20px 0;text-align:center;width:100%">{{ user.balance_formatted }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="content-wrap">
              <header class="content-header">
                <h3>Nutzung</h3>
              </header>
              <div class="content-body" style="text-align:center;padding:40px;">
                <router-link to="/balance/usage" class="button button-grey button-round button-small">
                  <img src="../../assets/icons/plus.svg">
                  <span>Nutzungsstatistiken anzeigen</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>-->

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("balance.methods") }}</h3>
            <p class="header-action">
              <router-link to="/balance/setup_intents/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("global.add") }}</span>
              </router-link>
            </p>
          </header>
          <div class="content-body">
            <div v-if="payment_methods.length > 0" class="balance-methods">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%"></th>
                    <th width="20%">{{ $t("global.methode") }}</th>
                    <th width="30%">{{ $t("global.details") }}</th>
                    <th width="30%">{{ $t("global.added") }}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="payment_method in payment_methods" v-bind:key="payment_method.uuid">
                    <td>
                      <span v-if="payment_method.is_default" class="pm-default">{{ $t("global.default") }}</span>
                    </td>
                    <td>
                      <span v-if="payment_method.method_type == 'sepa'">{{ $t("balance.sepa") }}</span>
                      <span v-if="payment_method.method_type == 'card'">{{ $t("balance.creditcard") }}</span>
                    </td>
                    <td v-if="payment_method.stripe_details">
                      <span v-if="payment_method.method_type == 'sepa'">
                        <strong>IBAN:</strong>
                        {{ payment_method.stripe_details.country }}XXXXXXXXXXXXXXXX{{ payment_method.stripe_details.last4 }}
                      </span>
                      <span v-if="payment_method.method_type == 'card'">
                        <strong>{{ $t("balance.card") }}:</strong> XXXX XXXX XXXX
                        {{ payment_method.stripe_details.last4 }}
                      </span>
                    </td>
                    <td>
                      {{ payment_method.created_at_formatted }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p style="margin: 35px 0; text-align: center">{{ $t("balance.empty") }}</p>
            </div>
          </div>
        </div>

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("global.invoices") }}</h3>
            <p class="header-action"></p>
          </header>
          <div class="content-body">
            <div v-if="invoices.length > 0" class="balance-methods">
              <table class="table">
                <thead>
                  <tr>
                    <th width="30%">{{ $t("global.status") }}</th>
                    <th width="30%">{{ $t("global.amount") }}</th>
                    <th width="30%">{{ $t("global.createdAt") }}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice in invoices" v-bind:key="invoice.uuid">
                    <td>
                      <span v-if="invoice.status == 'pending'">{{ $t("global.created") }}</span>
                      <span v-if="invoice.status == 'paid'">{{ $t("global.success") }}</span>
                    </td>
                    <td>
                      {{ invoice.amount_formatted }}
                    </td>
                    <td>
                      {{ invoice.created_at_formatted }}
                    </td>
                    <td style="text-align: right">
                      <a :href="invoice.stripe_pdf" v-if="invoice.stripe_pdf" class="material-icons" style="display: block; color: #444">picture_as_pdf</a>
                    </td>
                  </tr>
                  <div class="row row-gutter-20" style="margin-top: 20px">
                    <div class="col-12">
                      <a v-if="pagination.links.prev" @click="prev_page" class="button button-ci button-100 button-round button-small">{{ $t("global.prevPage") }}</a>
                      <a v-else>-</a>
                    </div>
                    <div class="col-12" style="text-align: right">
                      <a v-if="pagination.links.next" @click="next_page" class="button button-ci button-100 button-round button-small">{{ $t("global.nextPage") }}</a>
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p style="margin: 35px 0; text-align: center">{{ $t("balance.emptyInvoice") }}</p>
            </div>
          </div>
        </div>

        <!--<div class="content-wrap balance-deposit">
          <header class="content-header">
            <h3>Einzahlung</h3>
          </header>
          <div class="content-body">

            <div v-if="payment_loading">
              <div class="loading-wrap" style="margin:0;padding:10px 0">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="payment_methods.length > 0">
                <div class="row row-gutter-20">
                  <div class="col-6">
                    <p style="margin:0"><a @click="make_payment(25)" class="button button-ci button-round button-100">25 EUR</a></p>
                  </div>
                  <div class="col-6">
                    <p style="margin:0"><a @click="make_payment(100)" class="button button-ci button-round button-100">100 EUR</a></p>
                  </div>
                  <div class="col-6">
                    <p style="margin:0"><a @click="make_payment(500)" class="button button-ci button-round button-100">500 EUR</a></p>
                  </div>
                  <div class="col-6">
                    <p style="margin:0"><a @click="make_payment(5000)" class="button button-ci button-round button-100">5.000 EUR</a></p>
                  </div>
                </div>
              </div>
              <div v-else>
                <p style="margin:35px 0;text-align:center">Keine Bezahlmethode vorhanden. Bitte hinzufügen.</p>
              </div>
            </div>

          </div>
        </div>-->

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("balance.coupons") }}</h3>
          </header>
          <div class="content-body">
            <p style="margin: 0 0 20px 0">{{ $t("balance.couponsInfo") }}</p>
            <form @submit="add_coupon" class="form" method="post">
              <div class="form-wrap">
                <label for="coupon">{{ $t("balance.couponCode") }}</label>
                <input v-model="coupon_code" class="form-input" type="coupon" name="coupon" id="coupon" />
              </div>

              <p style="margin-bottom: 0; text-align: right">
                <input type="submit" :value="$t('balance.redeem')" class="button button-ci button-round button-100" />
              </p>
            </form>
          </div>
        </div>

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("balance.transactions") }}</h3>
          </header>
          <div class="content-body">
            <div v-if="payments.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th width="10%">{{ $t("global.status") }}</th>
                    <th width="10%">{{ $t("global.amount") }}</th>
                    <th width="20%">{{ $t("global.date") }}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="payment in payments" v-bind:key="payment.uuid">
                    <td>
                      <span v-if="payment.status == 'created'">{{ $t("global.created") }}</span>
                      <span v-if="payment.status == 'pending'">{{ $t("balance.pending") }}</span>
                      <span v-if="payment.status == 'completed'">{{ $t("global.success") }}</span>
                      <span v-if="payment.status == 'declined'">{{ $t("global.declined") }}</span>
                    </td>
                    <td>{{ payment.amount_formatted }}</td>
                    <td>{{ payment.created_at_formatted }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p style="margin: 35px 0; text-align: center">{{ $t("balance.transactionsEmpty") }}</p>
            </div>
          </div>
        </div>

        <!--<div class="content-wrap">
          <header class="content-header">
            <h3>Referrals</h3>
          </header>
          <div class="content-body">
            Test
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "balance",
  data() {
    return {
      errors: [],
      user: {},
      payment_methods: [],
      payment_customers: [],
      payment_intents: [],
      payments: [],
      invoices: [],
      coupon_code: "",
      payment_loading: false,
      pagination: {},
    };
  },
  methods: {
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/user/payments", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.payments = response.data.payments;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/user/invoices", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.invoices = response.data.invoices;
          this.pagination = response.data.meta.pagination;
        });
    },
    get_payment_methods() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user/payment_methods", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.payment_methods = response.data.payment_methods;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/stripe/payment_intents", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.payment_intents = response.data.payment_intents;
        });
    },
    get_payment_customers() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user/payment_customers", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.payment_customers = response.data.payment_customers;
        });
    },
    get_payments() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user/payments", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.payments = response.data.payments;
        });
    },
    add_payment_customer() {
      axios
        .post(process.env.VUE_APP_BASE_API + "/user/payment_customers", {}, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    make_test_payment() {
      axios
        .post(
          process.env.VUE_APP_BASE_API + "/user/payments",
          {
            intent_amount: 10.0,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    make_payment(amount) {
      this.payment_loading = true;

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/stripe/payment_intents",
          {
            intent_amount: amount,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "alert",
            type: "success",
            title: this.$t("notification.paymentSuccess"),
          });
          this.get_user();
          this.get_payment_methods();
          this.get_payment_customers();
          this.get_payments();
          this.payment_loading = false;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    delete_payment_customer(uuid) {
      axios
        .delete(process.env.VUE_APP_BASE_API + "/user/payment_customers/" + uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          console.log(response);
          this.get_payment_customers();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    add_coupon: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/user/coupons",
          {
            coupon_code: this.coupon_code,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.get_user();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    send_email(invoice) {
      axios
        .post(process.env.VUE_APP_BASE_API + "/user/invoices/" + invoice.uuid + "/send_email", {}, { headers: { Authorization: this.$store.getters.getToken } })
        .then(() => {
          this.get_user();
          this.$notify({
            group: "alert",
            type: "success",
            title: this.$t("notification.invoiceMail"),
          });
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    next_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.next.split("=")[1]);
    },
    prev_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.prev.split("=")[1]);
    },
  },
  mounted() {
    this.get_user();
    this.get_payment_methods();
    this.get_payment_customers();
    this.get_payments();
  },
};
</script>

<style>
p.balance {
  margin: 0;
  font-size: 30px;
}

.pm-default {
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}
</style>
